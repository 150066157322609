import {
  ClipboardDocumentListIcon,
  DocumentChartBarIcon,
} from "@heroicons/vue/24/outline";

const navigation = [
  // { name: 'Dashboard', href: '/', icon: Squares2X2Icon },
  { name: "Orders", href: "/orders", icon: ClipboardDocumentListIcon },
  { name: "Invoices", href: "/invoices", icon: DocumentChartBarIcon },
  // { name: 'Settings', href: '/settings', icon: Cog8ToothIcon }
];

export { navigation };
